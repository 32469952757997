<app-header></app-header>

<div class="container mt-4" *ngIf="!this.authService.isAuthenticated()">
    <form class="row g-3" [formGroup]="loginForm" novalidate (ngSubmit)="onLoginSubmit()">
        <h1 class="text-center">{{'admin-panel.enter' | translate}}</h1>
        <div class="col-md-6 offset-md-3">
            <mat-form-field class="w-100" appearance="fill">
                <mat-label>{{'admin-panel.login' | translate}}</mat-label>
                <input type="text" matInput formControlName="login" name="login" required>
                <mat-error>{{'admin-panel.login-err' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="w-100" appearance="fill">
                <mat-label>{{'admin-panel.password' | translate}}</mat-label>
                <input type="password" matInput formControlName="password" name="password" required>
                <mat-error>{{'admin-panel.password-err' | translate}}</mat-error>
            </mat-form-field>
                
            <button type="submit" mat-raised-button color="primary" class="w-100 my-2">{{'admin-panel.to-enter' | translate}}</button>
        </div>
    </form>
</div>

<div *ngIf="this.authService.isAuthenticated()">
    <div class="container">
        <div class="card bg-dark text-white rounded-3 mb-4">
            <div class="card-body p-3">
                <h3 class="card-title m-0">Панель администратора</h3>
                <button type="button" class="btn btn-sm" mat-button color="warn" (click)="logout()">Выход</button>
            </div>
        </div>

        <div class="my-5">
        <h1>Ссылка на Telegram-канал</h1>
        <form  (submit)="editLink()" novalidate [formGroup]="linkForm">
            <mat-form-field style="width: 90%;">
                <input  required placeholder="Ссылка" matInput formControlName="link">
                <mat-error>Поле "ссылка" обязательно для заполнения.</mat-error>
            </mat-form-field>            
            <div><button type="submit" mat-raised-button>Применить</button></div>
        </form></div>

        <div class="my-5">
        <h1>Страны</h1>
        <div class="my-2">
            <h2><button type="button" class="btn btn-sm" mat-raised-button color="primary" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasCreateCountry" aria-controls="offcanvasCreateCountry">
                    Открыть создание страны
                </button></h2>
        </div></div>

        <div class="my-5">
        <div class="container" style="box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), 0 -4px 4px rgba(0, 0, 0, 0.1);">
            <div class="table-responsive my-2">
                <table class="table table-striped w-100">
                    <thead>
                        <tr>
                            <th class="w-10">ID</th>
                            <th class="w-75">Название</th>
                            <th class="w-75">Код</th>
                            <th class="w-100">Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let country of countries" style="display: table-row;">
                            <td>{{ country[0] }}</td>
                            <td>{{ country[1] }}</td>
                            <td>{{ country[2] }}</td>
                            <td>
                                <button class="btn btn-sm m-1" mat-raised-button color="basic"
                                    (click)="changeSelectedCountry(country)" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasEditCountry"
                                    aria-controls="offcanvasEditCountry">Обновить</button>
                                <button class="btn btn-sm m-1" mat-raised-button color="warn"
                                    (click)="deleteCountry(country[0])">Удалить</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div></div>

        <div class="mt-4 mb-2">
            <h1>Ключи</h1>
            <h2><button type="button" class="btn btn-sm" mat-raised-button color="primary" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasCreateKey" aria-controls="offcanvasCreateKey">
                    Открыть создание ключа
                </button></h2>
        </div>

        <div class="my-2" style="box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), 0 -4px 4px rgba(0, 0, 0, 0.1);">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Страна</th>
                            <th>Ключ</th>
                            <th>Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let key of keys">
                            <td>{{ key[0] }}</td>
                            <td>{{ key[2] }}</td>
                            <td style="word-wrap: break-word;"><span
                                    style="white-space: pre-wrap; max-width: 300px; display: inline-block; overflow-wrap: break-word;">{{
                                    key[4] }}</span></td>
                            <td>
                                <button class="btn btn-sm m-1" mat-raised-button color="basic"
                                    (click)="changeSelectedKey(key)" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasEditKey"
                                    aria-controls="offcanvasEditKey">Обновить</button>
                                <button class="btn btn-sm m-1" mat-raised-button color="warn"
                                    (click)="deleteKey(key[0])">Удалить</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="mt-4 mb-2">
        <h1>Создание нового поста</h1>
        <app-blog-editor></app-blog-editor>
    </div>

    <div class="mt-4 mb-2">
        <h1>Все посты</h1>
        <div class="container" style="box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), 0 -4px 4px rgba(0, 0, 0, 0.1);">
            <div class="table-responsive my-2">
                <table class="table table-striped w-100">
                    <thead>
                        <tr>
                            <th class="w-10">ID</th>
                            <th class="w-75">Название</th>
                            <th class="w-75">Код</th>
                            <th class="w-100">Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let post of posts" style="display: table-row;">
                            <td>{{ post["id"] }}</td>
                            <td>{{ post["title"] }}</td>
                            <td><img style="width: 100%; max-height: 350px; object-fit: cover"
                                [src]="post['cover']"> 
                            </td>
                            <td>
                                <button class="btn btn-sm m-1" mat-raised-button color="basic"
                                (click)="changeSelectedPost(post)"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasEditPost"
                                    aria-controls="offcanvasEditPost">Обновить</button>
                                <button (click)="deletePost(post['id'])" class="btn btn-sm m-1" mat-raised-button color="warn"
                                    >Удалить</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>  

    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasCreateCountry"
        aria-labelledby="offcanvasCreateCountryLabel">
        <div class="offcanvas-header">
            <h5 id="offcanvasCreateCountryLabel">Создание страны</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <form (submit)="createCountry()" novalidate [formGroup]="countryForm">
                <mat-form-field>
                    <input required placeholder="Название" matInput formControlName="name">
                    <mat-error>Поле название обязательно для заполнения.</mat-error>
                </mat-form-field>
                <mat-label>
                    <p class="m-0">Коды флагов: <a href="https://flagicons.lipis.dev/" target="_blank">ссылка</a>
                    </p>
                    <p class="m-0">Предпросмотр иконки:
                        <span class="me-2 btn-icon flag-icon" [ngClass]=newCountryCode>
                        </span>
                    </p>
                </mat-label>
                <mat-form-field>
                    <input placeholder="Код страны (ru)" required matInput formControlName="code">
                    <mat-error>Поле код страны обязательно для заполнения.</mat-error>
                </mat-form-field>
                <div><button type="submit" mat-raised-button color="primary" data-bs-dismiss="offcanvas">Создать</button></div>
            </form>
        </div>
    </div>

    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasEditCountry"
        aria-labelledby="offcanvasEditCountryLabel">
        <div class="offcanvas-header">
            <h5 id="offcanvasEditCountryLabel">Изменение страны</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <form (submit)="updateCountry()" novalidate [formGroup]="editCountryForm">
                <mat-form-field>
                    <input required placeholder="Название" matInput formControlName="name">
                    <mat-error>Поле название обязательно для заполнения.</mat-error>
                </mat-form-field>
                <mat-label>
                    <p class="m-0">Коды флагов: <a href="https://flagicons.lipis.dev/" target="_blank">ссылка</a>
                    </p>
                    <p class="m-0">Предпросмотр иконки:
                        <span class="me-2 btn-icon flag-icon flag-icon-{{editCountryForm?.get('code')?.value}}">
                        </span>
                    </p>
                </mat-label>
                <mat-form-field>
                    <input placeholder="Код страны (ru)" required matInput formControlName="code">
                    <mat-error>Поле код страны обязательно для заполнения.</mat-error>
                </mat-form-field>
                <div><button type="submit" mat-raised-button color="primary" data-bs-dismiss="offcanvas">Изменить</button></div>
            </form>
        </div>
    </div>

    <div class="offcanvas offcanvas-end" id="offcanvasCreateKey" aria-labelledby="offcanvasCreateKeyLabel"
        style="z-index: 1050;">
        <div class="offcanvas-header">
            <h5 id="offcanvasCreateKeyLabel">Создание ключа</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <form (submit)="createKey()" style="z-index: 2000;" novalidate [formGroup]="keyForm">
                <mat-form-field>
                    <input required placeholder="Ключ" matInput formControlName="key">
                    <mat-error>Поле ключ обязательно для заполнения.</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Страна</mat-label>
                    <mat-select formControlName="country" cdkScrollable style="overflow: visible; z-index: 2000;">
                        <mat-option required *ngFor="let c of countries" [value]="c[0]" style="z-index: 2000;">{{
                            c[1]
                            }}</mat-option>
                    </mat-select>
                    <mat-error>Поле страна обязательно для заполнения.</mat-error>
                </mat-form-field>
                <div><button type="submit" mat-raised-button color="primary" data-bs-dismiss="offcanvas">Создать</button></div>
            </form>
        </div>
    </div>

    <div class="offcanvas offcanvas-end" id="offcanvasEditKey" aria-labelledby="offcanvasEditKeyLabel"
        style="z-index: 1050;">
        <div class="offcanvas-header">
            <h5 id="offcanvasEditKeyLabel">Изменение ключа</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <form (submit)="updateKey()" style="z-index: 2000;" novalidate [formGroup]="editKeyForm">
                <mat-form-field>
                    <input required placeholder="Ключ" matInput formControlName="key">
                    <mat-error>Поле ключ обязательно для заполнения.</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Страна</mat-label>
                    <mat-select formControlName="country" cdkScrollable style="overflow: visible; z-index: 2000;">
                        <mat-option *ngFor="let c of countries" [value]="c[0]" style="z-index: 2000;">{{ c[1]
                            }}</mat-option>
                    </mat-select>
                    <mat-error>Поле страна обязательно для заполнения.</mat-error>
                </mat-form-field>
                <div><button type="submit" mat-raised-button color="primary" data-bs-dismiss="offcanvas">Изменить</button></div>
            </form>
        </div>
    </div>

    <div class="offcanvas offcanvas-end" id="offcanvasEditPost" aria-labelledby="offcanvasEditPostLabel"
        style="z-index: 1050; width: 100vw !important">
        <div class="offcanvas-header">
            <h5 id="offcanvasEditKeyLabel">Изменение поста</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body d-flex flex-column">
            <div class="mb-3 d-flex flex-column"><p class="m-0">Обложка поста</p>
                <input type="file" (change)="onFileSelected($event)" accept="image/*" required>
                <img *ngIf="imageBase64" style="max-width: 700px;" class="img img-fluid" [src]="imageBase64"></div>
                
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Заголовок поста</mat-label>
                    <input type="text" matInput [(ngModel)]="postTitle" required>
                    <mat-error>Необходим заголовок поста</mat-error>
                </mat-form-field>
                
                <angular-editor [(ngModel)]="htmlContent1" (ngModelChange)="onHtmlChange()" [config]="editorConfig">
                </angular-editor>
                
                <div class="mt-2"><button (click)="editPost()" type="submit" color="primary" mat-raised-button>Изменить</button></div>
        </div>
    </div>
</div>