<div *ngIf="keys==null || keys.length==0 " >
  <div class="d-flex flex-column justify-content-center align-items-center h-100 p-2 m-5">    
    <mat-spinner style="width: 4rem; height: 4rem;"></mat-spinner> 
    <p class="p-0 m-0 text-center">{{"key.loading"| translate}}</p>
  </div>
</div>


<div class="mt-3" id="keys">
    <div *ngFor="let key of keys.slice((currentPage - 1) * pageSize, currentPage * pageSize)" class="card mb-3">
        <a href="/key/{{key[0][0]}}/" class="row g-0 text-decoration-none">
            <div class="col">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                        <h3 class="p-0 m-0">{{key[0][1]}} #{{key[0][0]}}</h3>
                        <span class="me-2 btn-icon flag-icon flag-icon-{{key[0][2]}}"
                            style="height: 40px; width: 60px;border-radius: 3px;"></span>
                    </div>
                </div>
                <div class="card-footer text-muted d-flex justify-content-between align-items-center">
                    <span class="d-flex align-items-center">
                        <i class="timer icons me-1"></i>
                        {{"country.published"| translate}} {{getTime(key[0][3])[0]}} {{getTime(key[0][3])[1] | translate}} </span>
                      <div class="d-flex align-items-center">
                        <i class="status icons me-1"></i>
                        <span *ngIf="key[1]==-1" class="text-success">Online</span>
                        <span *ngIf="key[1]!=-1" class="text-success">Online, {{key[1]}}{{"time.ms"| translate}}</span>
                      </div>
                </div>
            </div>
        </a>
    </div>
</div>


<nav *ngIf="keys!=null && keys.length>0 " aria-label="Page navigation example">
  <ul class="pagination justify-content-center">
    <li class="page-item" [class.disabled]="currentPage === 1 || currentPage === 0">
      <a class="page-link" (click)="changePage(1)" aria-label="Previous">
        <span class="sr-only"><<</span>
      </a>
    </li>
    <li
      *ngFor="let page of getPageNumbers().slice(getStartIndex(), getEndIndex())"
      class="page-item" [class.active]="currentPage === page">
      <a class="page-link" (click)="changePage(page)" [class.active]="currentPage === page">{{page}}</a>
    </li>
    <li class="page-item" [class.disabled]="currentPage === totalPages || currentPage === 0">
      <a class="page-link" (click)="changePage(totalPages)" aria-label="Next">
        <span>>></span>
      </a>
    </li>
  </ul>
</nav>
