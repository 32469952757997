<div class="mb-3"><p class="m-0">Обложка поста</p>
<input type="file" (change)="onFileSelected($event)" accept="image/*" required>
<img *ngIf="imageBase64" class="img img-fluid" [src]="imageBase64"></div>

<mat-form-field class="w-100" appearance="fill">
    <mat-label>Заголовок поста</mat-label>
    <input type="text" matInput [(ngModel)]="postTitle" required>
    <mat-error>Необходим заголовок поста</mat-error>
</mat-form-field>

<angular-editor [(ngModel)]="htmlContent1" (ngModelChange)="onHtmlChange()" [config]="editorConfig">
</angular-editor>

<div class="mt-2"><button (click)="createPost()" type="submit" color="primary" mat-raised-button>Создать</button></div>