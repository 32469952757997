<app-header></app-header>

<div class="mt-3" id="keys">
    <div *ngIf="key && key.length > 0; else noKeys">
        <div *ngFor="let k of key" class="card mb-3">
            <a href="/key/{{k[0][0]}}/" class="row g-0 text-decoration-none">
                <div class="col">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <h3 class="p-0 m-0">{{k[0][1]}} #{{k[0][0]}}</h3>
                            <span class="me-2 btn-icon flag-icon flag-icon-{{k[0][2]}}"
                                style="height: 40px; width: 60px;border-radius: 3px;"></span>
                        </div>
                    </div>
                    <div class="card-footer text-muted d-flex justify-content-between align-items-center">
                        <span class="d-flex align-items-center">
                            <i class="timer icons me-1"></i>
                            {{'country.published' | translate}} {{getTime(k[0][3])[0]}} {{getTime(k[0][3])[1] | translate}} </span>
                        <div class="d-flex align-items-center">
                            <i class="status icons me-1"></i>
                            <span *ngIf="k[1]==-1">Online</span>
                        <span *ngIf="k[1]!=-1" class="text-success">Online, {{k[1]}}{{"time.ms"| translate}}</span>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <ng-template #noKeys>
        <p>{{'country.no-keys' | translate}}</p>
    </ng-template>
</div>

<hr class="my-5">
<p>{{'country.other-countries' | translate}}</p>
<app-country-list-component></app-country-list-component>
